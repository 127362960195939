.space {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eff2f5;
}

.spin {
  font-size: 25px;
  color: #033238;
}

.icon {
  display: flex;
  align-items: center;
  color: #033238;
  font-size: 50px;
}
