.image_box {
  position: relative;
  padding: 10px 0;
  width: 250px;
  max-width: 500px;
}

.remove {
  position: absolute;
  top: 10px;
  right: 5px;
}
