.AnonLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #efefef;

  .Menu {
    padding-bottom: 10px;
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 34px;
    background-color: #ffffff;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 9%);

    .Children {
      width: 100%;
      padding: 0;
    }
  }
}

@media (max-width: 650px) {
  .AnonLayout,
  .Content,
  .Children {
    padding: 10px;
  }
}
