@import '../../assets/variabless.scss';

.IndicateWrapper {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: -2px;

  .IndicateItem {
    height: 3px;
    flex-grow: 1;
    background-color: $C-GREEN-MAIN-LIGHT;
    border-radius: 2px;

    &.Success {
      background-color: $C-GREEN;
    }

    &.Error {
      background-color: $C-RED--400;
    }
  }

  .IndicateItem:not(:first-child) {
    margin-left: 5px;
  }
}
