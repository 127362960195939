$C-BLACK: rgba(0, 0, 0, 1);
$C-BLACK-LIGHT: rgba(0, 0, 0, 0.2);
$C-WHITE: #fff;

$C-ACCENT: #0a1722;

$C-TEXT: rgba(0, 0, 0, 1);

$C-BLUE: #0484ac;
$C-PINK: #fdf0f0;
$C-PINK_DARK: #e56666;
$C-GREEN: #029487;

$C-YELLOW: #eccf39;
$C-YELLOW--100: rgba(236, 207, 57, 0.1);
$C-YELLOW--200: rgba(236, 207, 57, 0.2);
$C-YELLOW--400: #eccf39;

$C-VIOLET--100: #c7bcf517;
$C-VIOLET--200: #c7bcf54d;
$C-VIOLET--400: #5b39e4;

$C-BLUE--100: rgba(45, 134, 185, 0.1);
$C-BLUE--200: rgba(45, 134, 185, 0.2);
$C-BLUE--400: rgb(45, 134, 185);

$C-ORANGE: rgb(255, 155, 63);
$C-ORANGE--100: #f9923317;
$C-ORANGE--200: #f9923336;
$C-ORANGE--400: rgb(255, 155, 63);

$C-RED--100: #fdeceb;
$C-RED--200: rgba(223, 64, 64, 0.2);
$C-RED--400: #df4040;
$C-RED: #d63125;
$C-RED_DARK: #c71f12;

$C-ACTION_BUTTON: #f7a132;
$C-ACTION_BUTTON--ACTIVE: #e28610;

$C-GREY_LIGHT: #ececec;
$C-GREY_LIGHT--100: #efefef;
$C-GREY_LIGHT--200: #f7f7f7;
$C-GREY_MEDIUM: #c6c6c6;
$C-GREY_DARK: rgba(0, 0, 0, 0.38);
$C-GREY: #778899;

$C-SUCCESS: #4caf50;
$C-ERROR: #d63125;

$C-MODAL_BG: rgba(0, 0, 0, 0.5);

$C-GREEN--100: #eef6f6;
$C-GREEN--200: #d4efef;
$C-GREEN--400: #029487c2;
$C-GREEN--LIGHT: #029487c2;
$C-GREEN-MAIN: #1e7985;
$C-GREEN-MAIN--100: #008080;
$C-GREEN-MAIN-LIGHT: rgba(30, 121, 133, 0.2);

$C-LABEL_COLOR: rgba(0, 0, 0, 0.54);

$C-INPUT_BORDER: rgba(0, 0, 0, 0.15);
