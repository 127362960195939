@import '../../../assets/variabless.scss';

input[placeholder] {
  text-overflow: ellipsis;
  width: 100%;
}

.input {
  margin-bottom: 5px;
}
