.DateRangePickerDropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.DateRangePickerDropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.DateRangePickerDropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.DateRangePickerDropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #C5C8CF !important;
}
